










































































import { Component, Vue } from "vue-property-decorator";
import { AppUser } from "@/models/user";
import { ApiEndPoints } from "@/helpers/api_endpoints";
import Notifier from "@/helpers/notifier";
import _axios from "@/plugins/axios";

import agent from "@/helpers/agent";
import { BusinessDocument } from "@/models/businessDocument";
import {
  BusinessDocumentType,
  DocumentProfile,
  DocumentStatus,
} from "@/models/businessDocument";

@Component
export default class OrganizationsView extends Vue {
  private documents: BusinessDocument[] = [];
  pagination = {
    sortBy: "Type",
    descending: false,
    page: 0,
    rowsPerPage: 8,
    rowsNumber: 10,
  };
  loading = true;
  filter = "";
  columns = [
    {
      name: "name",
      required: true,
      label: "File Name",
      align: "left",
      field: (row: BusinessDocument) => row.originalFileName,
      sortable: true,
      classes: "bg-grey-2 ellipsis",
      headerClasses: "bg-primary text-white",
    },
    {
      name: "Profile",
      required: true,
      label: "Document Profile",
      align: "left",
      field: (row: BusinessDocument) => row.documentProfile,
      sortable: true,
    },
    {
      name: "Type",
      required: true,
      label: "Type",
      align: "left",
      field: (row: BusinessDocument) => row.type,
      sortable: true,
    },
    {
      name: "Status",
      required: true,
      label: "Status",
      align: "left",
      field: (row: BusinessDocument) => row.status,
      format: (val: string) => `${val}`,
      sortable: false,
    },
    {
      name: "actions",
      required: true,
      label: "Actions",
      align: "right",
      field: "Actions",
    },
  ];

  lockedIcon(row: AppUser): string {
    return row.lockoutEnd === null ? "lock" : "lock_open";
  }
  profile(profile: DocumentProfile) {
    return DocumentProfile[profile];
  }
  type(type: BusinessDocumentType) {
    return BusinessDocumentType[type];
  }
  status(status: DocumentStatus) {
    return DocumentStatus[status];
  }
  getStatus(status: DocumentStatus) {
    return DocumentStatus[status];
  }
  viewDocument(document: BusinessDocument) {
    agent.BusinessDocuments.getProfileDocumentsByType(
      document.documentID,
      document.type
    ).then((documents) => {
      this.$router.push({
        path: "/view-documents",
        query: {
          id: document.documentID,
          fileName: BusinessDocumentType[document.type],
          author: document.author?.fullName,
          reference: document.originalFileName,
          status: DocumentStatus[document.status],
        },
      });
    });
  }
  mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: "",
    });
  }
  onRequest(props: any = {}) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const search = props.filter;
    this.loading = true;
    // get all rows if "All" (0) is selected
    const size = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage;
    agent.BusinessDocuments.list(search, descending, page, size, sortBy).then(
      (rows) => {
        //populate table
        this.documents = rows.rows;
        this.pagination.rowsNumber = rows.rowCount;

        // don't forget to update local pagination object
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;

        // ...and turn of loading indicator
        this.loading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  goToUpdate(id: string) {
    this.$router.push({
      name: "ProfilesApplication",
      query: { id },
    });
  }
  exportData() {
    const token = this.$store.getters["app/getToken"];
    return _axios
      .get(`api/${ApiEndPoints.financialInstitutions}/export-to-excel`, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/octet-stream",
          })
        );
        const fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.download = "Documents.xlsx";
        document.body.appendChild(fileLink);
        fileLink.click();
      });
  }
  deleteDocument(id: string) {
    agent.BusinessDocuments.delete(id).then(() => {
      const index = this.documents.findIndex((i) => i.documentID == id);
      this.documents.splice(index, 1);
      Notifier.showInfo(`Successfully deleted`);
    });
  }
}
